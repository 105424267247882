/* @flow */

import * as React from 'react'
import { ProductTemplate } from '../templates'

type Props = {
  location: {
    href: string,
    state: Object,
  },
}

export default function Form(props: Props) {
  const { location } = props
  return (
    <ProductTemplate
      url={location.href}
      media="illu_product-clickandcollect.png"
      title="Créez votre boutique en ligne et augmentez votre visibilité "
      name="Click and collect"
      label="Potico"
      description="Grâce au click and collect, fidélisez et développez votre clientèle en lui permettant de commander vos produits, de les payer en ligne puis de venir les récupérer en magasin."
      body={[
        {
          name: 'features',
          items: [
            {
              media: 'icon_product-clickandcollect_newchannel.png',
              title: 'Nouveau canal de vente',
              content: 'Boostez vos ventes grâce à la boutique en ligne des cavistes et épiciers',
            },
            {
              media: 'icon_product-clickandcollect_onlineorder.png',
              title: 'Gestion des ventes en ligne',
              content: 'Gérez vos commandes en ligne facilement depuis une unique interface',
            },
            {
              media: 'icon_product-clickandcollect_stock.png',
              title: 'Gestion des stocks optimale',
              content:
                'Gérez vos stocks grâce à la connexion du site e-commerce au logiciel de caisse',
            },
            {
              media: 'icon_product-clickandcollect_promotion.png',
              title: `Création d'offres promotionnelles`,
              content: 'Proposez des offres avantageuses simplement sur votre site e-commerce',
            },
          ],
        },
        {
          name: 'calloutCards',
          items: [
            {
              decoration: 'mujeres',
              media: 'screenshot_clickandcollect_beautifulpage.png',
              alt: 'nouveau canal de vente',
              label: 'Nouveau canal de vente',
              title: 'Boostez vos ventes grâce à la boutique en ligne des cavistes et épiciers',
              content: `Gardez le contact avec votre clientèle existante et faites-vous connaître grâce une plateforme e-commerce. Créez des fiches produit détaillées, ajoutez des notes de dégustation mettant en avant votre savoir-faire et la qualité de vos produits. Rien de mieux pour s'adapter aux nouveaux modes de consommation des consommateurs et booster vos ventes en ligne.`,
            },
            {
              decoration: 'gypsyeyes',
              media: 'screenshot_clickandcollect_ordermanagement.png',
              alt: 'gestion des ventes en ligne',
              label: 'Gestion des ventes en ligne',
              title: 'Gérez vos commandes en ligne facilement',
              content: `À chaque étape de préparation de la commande, un mail est automatiquement envoyé à votre client : récapitulatif de la commande passée, confirmation de votre prise en charge, commande prête pour le retrait en click and collect ou par livraison. Un outil de paiement en ligne sécurisé est également intégré à la solution de vente en ligne pour une utilisation optimale.`,
            },
          ],
        },
        {
          name: 'informationInsert',
          items: [
            {
              media: 'security',
              title: 'Une mise en route de votre boutique en ligne Potico facilitée',
              content:
                "Une documentation est disponible depuis le centre d'aide Wino sous la forme d'une F.A.Q pour créer et mettre à jour votre compte. De plus, notre équipe support client est à disposition sur le chat pour répondre à toutes vos questions. ",
              href: 'https://help.wino.fr/fr/',
              linkText: `Découvrir le centre d'aide`,
              target: '_blank',
            },
            {
              media: 'compliance',
              title: 'Un guide pour caviste et épicier connecté',
              content:
                "Avec ce guide, familiarisez-vous avec des notions importantes comme le référencement naturel, l'acquisition payante, le content marketing, la mise en avant de produits en ligne, etc, pour aborder sereinement la transformation numérique au sein de votre boutique.",
              href:
                'https://content.wino.fr/le-guide-du-caviste-et-epicier-connecte-vente-communication-et-visibilite-en-ligne',
              linkText: 'Télécharger le guide',
              target: '_blank',
            },
          ],
        },
        {
          name: 'calloutCards',
          items: [
            {
              decoration: 'quarantine',
              media: 'screenshot_clickandcollect_stock.png',
              alt: 'gestion des stocks optimale',
              label: 'Gestion des stocks optimale',
              title:
                'Gérez vos stocks grâce à la connexion du site  e-commerce au logiciel de caisse',
              content:
                'Grâce à la connexion de la solution e-commerce Potico à la solution de caisse Wino, vous allez gagner du temps. Vos stocks sont automatiquement mis à jour sur Potico selon vos ventes générées et vos approvisionnements. Le client est informé sur Potico quand un produit est en rupture de stock. Vous gérez la vente en ligne sereinement.',
            },
            {
              decoration: 'gypsyeyes',
              media: 'screenshot_clickandcollect_promotion.png',
              alt: `création d'offres promotionnelles`,
              label: `Création d'offres promotionnelles`,
              title: 'Proposez des offres avantageuses simplement sur votre site e-commerce ',
              content: `Fidélisez facilement vos clients et attirez ceux qui ne vous connaissent pas encore en leur proposant des offres avantageuses sur votre boutique en ligne. Grâce à Potico, il est possible d'effectuer des promotions sur certains produits de cave et d'épicerie grâce à des prix remisés ou à des codes promotionnels. `,
            },
          ],
        },
      ]}
    />
  )
}
